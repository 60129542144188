.wrapper_section_two {
  font-family: "HelveticaLTBlkCond";
  background-color: var(--primaryColor);
  padding: 6%;
  .lighterText {
    font-family: "HelveticaLTLightCond";
  }
}
@media screen and (min-width: 1024px) {
  /* First Section  */
  // Second Section
  .wrapper_section_two {
    display: flex;
    height: 200px;
    .secondSecDesktop {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 2rem; /* 48px */
      line-height: 1;
      flex-direction: row;
      .box_text {
        align-items: center;
      }
      .mapa {
        width: 250px;
      }
    }
    .secondSecMobile {
      display: none;
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .wrapper_section_two {
    display: flex;
    padding: 6%;
    flex-direction: column;
    height: 350px;
    font-size: 2rem; /* 48px */
    line-height: 1;
    .secondSecDesktop {
      display: none;
    }
    .secondSecMobile {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .mapa {
        width: 250px;
        margin-left: auto;
        margin-right: auto;
      }
      .box_text {
        padding-top: 30px;
        // text-align: center;
        text-align: justify;
        -moz-text-align-last: center;
        text-align-last: center;
        width: 500px;
        .hca {
          color: var(--thirdColor);
          font-size: 2rem;
          line-height: 1;
        }
      }
      .box_textMobile{
        display: none;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  //mobile
  .secondSecDesktop {
    display: none;
  }
  .secondSecMobile {
    align-items: center;
    justify-content: center;
    .mapa {
      width: 250px;
      margin-left: auto;
      margin-right: auto;
    }
    .box_text {
      padding-top: 30px;
      margin-left: auto;
      margin-right: auto;
      -moz-text-align-last: center;
      text-align-last: center;
      font-size: 1rem;
      line-height: 1;
      width: 290px;
      .hca {
        color: var(--thirdColor);
        font-size: 1rem;
        line-height: 1;
      }
    }
  }
}
