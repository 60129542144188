
.wrapper_modal_popup {
    background-color: rgba(0, 31, 92,0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
    top: 0;
    left: 0;
    display: none;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    &.inShow {
      display: flex;
    }
    .modal_content {
      max-width: 30%;
      margin: 0 auto;
      position: relative;
      .btnInCLose {
        position: absolute;
        top: 0;
        right: 0;
        background: rgba(255, 255, 255, 1);
        font-size: 0.7rem;
        padding: 0.15rem 0.35rem;
        opacity: 0.5;
        border-radius: 0.25rem;
        cursor: pointer;
        &:hover {
          opacity: 1;
        }
      }
      img {
        // max-height: 450px;
        max-height: 100%;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .wrapper_modal_popup {
      .modal_content {
        max-width: 100%;
      }
  }
}