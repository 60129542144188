@media screen and (min-width: 1024px) {
  //Desktop
  .wrapper_section_four {
    font-family: "HelveticaLTBlkCond";
    padding-top: 6%;
    padding-left: 6%;
    background-color: rgb(242, 244, 247);
    padding-bottom: 2%;
    .topFour {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      // width: 100%;
      .topTitle {
        color: var(--secondaryColor);
        font-size: 1.875rem; /* 30px */
        line-height: 2.25rem; /* 36px */
        .secondText {
          color: rgb(29, 68, 149);
        }
      }
    }
    .emergency {
      justify-content: space-between;
    }
    .bottomFour {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 2%;
      .textContainer {
        color: var(--thirdColor);
        .ltlText {
          font-family: HelveticaLTLightCond;
        }
        .titleBlue {
          padding-top: 2%;
        }
      }
      .blueContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: var(--secondaryColor);
        height: 180px;
        width: 534px;
        flex-wrap: wrap;
        .blueInfo {
          color: white;
          padding-top: 5%;
          padding-left: 5%;
          .lighterText {
            font-family: HelveticaLTLightCond;
          }
          .lightText {
            font-family: HelveticaLTLightCond;
          }
          .lightTextpad {
            font-family: HelveticaLTLightCond;
            padding-bottom: 5%;
          }
        }
        .blueBorde {
          .franja {
            height: 180px;
          }
        }
      }
    }
  }

  .emergency {
    background-color: rgb(194, 32, 47);
    color: white;
    padding-left: 5%;
    float: right;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    width: 534px;
    .ambulance {
      width: 15%;
      padding-right: 5%;
    }
  }
  .topFourIpad {
    display: none;
  }
  .bottomFourIpad {
    display: none;
  }
  .emergencyIpad {
    display: none;
  }
  .blueContainerIpad {
    display: none;
  }
}
@media screen and (max-width: 1023px) {
  .wrapper_section_four {
    font-family: "HelveticaLTBlkCond";
    padding-top: 6%;
    // padding-left: 6%;
    background-color: rgb(242, 244, 247);
    padding-bottom: 2%;
    .topFour {
      display: none;
    }
    .bottomFour {
      display: none;
    }
    .topFourIpad {
      padding-left: 6%;
      .topTitleIpad {
        color: var(--secondaryColor);
        font-size: 3rem; /* 48px */
        line-height: 1;
        .secondTextIpad {
          color: rgb(29, 68, 149);
        }
      }
    }
    .bottomFourIpad {
      .menus {
        padding-left: 6%;
        padding-right: 6%;
        display: flex;
        flex-direction: row;
        padding-bottom: 20px;
        .menuList {
          .titleBlueIpad {
            justify-content: center;
            text-align: left;
            color: var(--thirdColor);
            font-size: 2.2rem; /* 48px */
            line-height: 1.5;
            .ltlTextIpad {
              line-height: 1;
              padding-left: 25px;
              font-family: "HelveticaLTCond";
            }
          }
        }
        .noMenuList {
          .titleBlueIpad {
            justify-content: center;
            text-align: left;
            color: var(--thirdColor);
            font-size: 2.2rem; /* 48px */
            line-height: 1.5;
          }
        }
      }
    }
    .emergencyIpad {
      background-color: rgb(197, 41, 48);
      color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      width: 100%;
      padding-left: 0%;
      .redEmergency {
        padding-left: 6%;
      }
      .ambulanceIpad {
        width: 13%;
        padding-right: 5%;
      }
    }
    .blueContainerIpad {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: var(--secondaryColor);
      height: 180px;
      flex-wrap: wrap;
      .blueInfoIpad {
        color: white;
        padding-top: 5%;
        padding-left: 5%;
        .lighterTextIpad {
          font-family: HelveticaLTLightCond;
        }
        .lightTextIpad {
          font-family: HelveticaLTLightCond;
        }
        .lightTextpadIpad {
          font-family: HelveticaLTLightCond;
          padding-bottom: 5%;
        }
      }
      .blueBordeIpad {
        .franjaIpad {
          height: 180px;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  //mobile
  .wrapper_section_four {
    font-family: "HelveticaLTBlkCond";
    padding-top: 6%;
    // padding-left: 6%;
    background-color: rgb(242, 244, 247);
    padding-bottom: 2%;
    .topFour {
      display: none;
    }
    .bottomFour {
      display: none;
    }
    .topFourIpad {
      padding-left: 6%;
      .topTitleIpad {
        color: var(--secondaryColor);
        font-size: 2rem; /* 48px */
        line-height: 1;
        .secondTextIpad {
          color: rgb(29, 68, 149);
        }
      }
    }
    .bottomFourIpad {
      .menus {
        padding-left: 6%;
        padding-right: 6%;
        display: flex;
        flex-direction: row;
        .menuList {
          .titleBlueIpad {
            justify-content: center;
            text-align: left;
            color: var(--thirdColor);
            font-size: 1rem; /* 48px */
            line-height: 1.5;
            .ltlTextIpad {
              line-height: 1;
              font-size: 1rem;
              padding-left: 25px;
              font-family: "HelveticaLTCond";
            }
          }
        }
        .noMenuList {
          .titleBlueIpad {
            justify-content: center;
            text-align: left;
            color: var(--thirdColor);
            font-size: 1rem; /* 48px */
            line-height: 1.2;
          }
        }
      }
    }
    .emergencyIpad {
      background-color: rgb(197, 41, 48);
      color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      width: 100%;
      padding-left: 0%;
      .redEmergency {
        padding-left: 6%;
      }
      .ambulanceIpad {
        width: 13%;
        padding-right: 5%;
      }
    }
    .blueContainerIpad {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: var(--secondaryColor);
      height: 180px;
      flex-wrap: wrap;
      .blueInfoIpad {
        color: white;
        padding-top: 5%;
        padding-left: 5%;
        .lighterTextIpad {
          font-family: HelveticaLTLightCond;
        }
        .lightTextIpad {
          font-family: HelveticaLTLightCond;
        }
        .lightTextpadIpad {
          font-family: HelveticaLTLightCond;
          padding-bottom: 5%;
        }
      }
      .blueBordeIpad {
        .franjaIpad {
          height: 180px;
        }
      }
    }
  }
}