@media screen and (min-width: 1024px) {
  //Desktop
  .wrapper_section_five {
    font-family: "HelveticaLTBlkCond";
    padding-top: 6%;
    padding-left: 6%;
    background-color: rgb(255, 255, 255);
    .topFive {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .topTitle {
        color: var(--secondaryColor);
        font-size: 1.875rem; /* 30px */
        line-height: 2.25rem; /* 36px */
        .secondText {
          color: rgb(29, 68, 149);
        }
      }
    }
    .bottomFive {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .menus {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .textContainer {
          color: var(--thirdColor);
          .titleBlue {
            padding-top: 2%;
          }
        }
        .blueContainer {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          background-color: var(--secondaryColor);
          height: 231px;
          width: 534px;
          .blueInfo {
            color: white;
            padding-top: 5%;
            padding-left: 5%;
            .lighterText {
              font-family: HelveticaLTLightCond;
            }
            .lightText {
              font-family: HelveticaLTLightCond;
            }
            .lightTextpad {
              font-family: HelveticaLTLightCond;
              padding-bottom: 5%;
            }
          }
          .blueBorde {
            .franja {
              height: 231px;
            }
          }
        }
      }
      .blueContainerMobile {
        display: none;
      }
    }
  }
}
@media screen and (max-width: 1023px) {
  //iPad
  .wrapper_section_five {
    font-family: "HelveticaLTBlkCond";
    background-color: rgb(242, 244, 247);
    .topFive {
      .topTitle {
        color: var(--secondaryColor);
        font-size: 3rem; /* 48px */
        line-height: 1;
        padding-top: 6%;
        padding-left: 6%;
        .secondText {
          color: rgb(29, 68, 149);
        }
      }
    }
    .bottomFive {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-top: 2%;
      .menus {
        display: flex;
        align-items: flex-start;
        justify-content: space-evenly;
        width: 100%;
        padding-bottom: 20px;
        .textContainer {
          color: var(--thirdColor);
          .titleBlue {
            justify-content: center;
            text-align: left;
            color: var(--thirdColor);
            font-size: 2.2rem; /* 48px */
            line-height: 1.5;
            // padding-left: 6%;
          }
        }
      }
      .blueContainer {
        display: none;
      }
      .blueContainerMobile {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: var(--secondaryColor);
        height: 231px;
        padding: 0%;
        // width: 534px;
        .blueInfo {
          color: white;
          padding-top: 5%;
          padding-left: 5%;
          .lighterText {
            font-family: HelveticaLTLightCond;
          }
          .lightText {
            font-family: HelveticaLTLightCond;
          }
          .lightTextpad {
            font-family: HelveticaLTLightCond;
            padding-bottom: 5%;
          }
        }
        .blueBorde {
          .franja {
            height: 231px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  //mobile
  .wrapper_section_five {
    font-family: "HelveticaLTBlkCond";
    background-color: rgb(242, 244, 247);
    .topFive {
      .topTitle {
        color: var(--secondaryColor);
        font-size: 2rem; /* 48px */
        line-height: 1;
        padding-top: 6%;
        padding-left: 6%;
        .secondText {
          color: rgb(29, 68, 149);
        }
      }
    }
    .bottomFive {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-top: 2%;
      .menus {
        display: flex;
        align-items: flex-start;
        justify-content:flex-start;
        // flex-wrap: wrap-reverse;
        // width: 100%;
        padding-right: 3%;
        .textContainer {
          color: var(--thirdColor);
          padding-left: 6%;
          // padding-right: 6%;
          .titleBlue {
            justify-content: center;
            text-align: left;
            color: var(--thirdColor);
            font-size: 1rem; /* 48px */
            line-height: 1.2;
            // padding-left: 6%;
          }
        }
      }
      .blueContainer {
        display: none;
      }
      .blueContainerMobile {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: var(--secondaryColor);
        height: 231px;
        padding: 0%;
        // width: 534px;
        .blueInfo {
          color: white;
          padding-top: 5%;
          padding-left: 5%;
          .lighterText {
            font-family: HelveticaLTLightCond;
          }
          .lightText {
            font-family: HelveticaLTLightCond;
          }
          .lightTextpad {
            font-family: HelveticaLTLightCond;
            padding-bottom: 5%;
          }
        }
        .blueBorde {
          .franja {
            height: 231px;
          }
        }
      }
    }
  }
}
