@use "uniform" as *;

.App {
  text-align: center;
}

:root {
  --primaryColor: rgb(250, 250, 250);
  --secondaryColor: rgb(0, 31, 92);
  --thirdColor: rgb(0,182,215);
  --forthColor: rgb(229,247,251);
  --fifthColor: rgb(217, 0, 36);
  scroll-padding-top: 150px;
  scroll-behavior: smooth;
}

ion-icon{
  font-size: 24px;
  color: rgb(29, 68, 149);
}
@media screen and (min-width: 1024px) {
  .BMM{
    display: none;
  }
}

@media screen and (max-width: 767px) {
  //mobile
  ion-icon{
    font-size: 8px;
    color: rgb(29, 68, 149);
  }
  .BMD{
    display:none;
  }
}