@media screen and (min-width: 1024px) {
  //Desktop
  .wrapper_section_seven {
    font-family: "HelveticaLTBlkCond";
    background-color: rgb(255, 255, 255);
    text-align: center;
    padding-top: 3%;
    padding-bottom: 3%;
    border-bottom: 2px solid var(--secondaryColor);
    border-top: 2px solid var(--secondaryColor);
    .minitext{
        font-family: "HelveticaLTBoldCond";
        color:var(--secondaryColor);
        line-height: 14px;
    }
  }
}

@media screen and (max-width: 1023px) {
.wrapper_section_seven {
    font-family: "HelveticaLTBlkCond";
    background-color: rgb(255, 255, 255);
    text-align: center;
    padding-top: 3%;
    padding-bottom: 3%;
    .minitext{
        font-family: "HelveticaLTBoldCond";
        color:var(--secondaryColor);
        line-height: 14px;
    }
  }
}
@media screen and (max-width: 767px) {
.tel{
  font-size: 3rem; /* 48px */
  line-height: 1;
}
}