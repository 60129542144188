@media screen and (min-width: 1024px) {
  //Desktop
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 150px;
    z-index: 999;
    background-color: var(--primaryColor);
    flex-direction: row;
    padding-right: 6%;
    padding-left: 7%;
    top: 0;
    .headerMobile {
      display: none;
    }
    .box_options {
      align-items: center;
      font-family: HelveticaLTBlkCond;
      padding-right: 50px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
    .box_options_two {
      display: none;
    }
    .box_brand {
      position: relative;
      display: flow-root;
      align-items: center;
      padding-left: 50px;
      justify-content: flex-start;
    }
    .logo {
      height: 50px;
    }
    ul {
      list-style: none;
      color: var(--secondaryColor);
    }
    li {
      margin: 10px;
    }
    a {
      margin: 0px;
      display: block;
      width: 100%;
      height: 100%;
    }

    .btn {
      border-radius: 30px;
      min-width: 150px;
      height: 40px;
      padding-top: 3px;
      text-align: center;
      position: relative;
      background: var(--secondaryColor);
      color: var(--primaryColor) !important;
    }
    .btn:hover {
      background: var(--thirdColor);
      color: white !important;
    }
  }
  .menu {
    position: absolute;
    top: 0;
    list-style-type: none;
    padding-top: 5px;
    height: 40px;
    padding: 0;
    min-width: 150px;
  }
  .menu-item {
    min-width: 150px;
    height: 40px;
    padding-top: 5px;
    text-align: center;
    position: relative;
    border-top: 1px solid var(--thirdColor);
  }
  .menu-item_special{
        min-width: 150px;
        height: 40px;
        padding-top: 5px;
        text-align: center;
        position: relative;
        border-top: 1px solid var(--secondaryColor);
      }
  .menu > li {
    margin: 0;
    color: var(--primaryColor);
    background-color: var(--secondaryColor);    
    text-align: left;
    padding-top: 2%;
    justify-content: center;
    padding: 3px;
  }
  .buttonMenu {
    padding-top: 3%;
    justify-content: center;
  }
  .menu > li:hover {
    background-color: var(--thirdColor);
  }

  .menu > li > button {
    width: 100%;
    height: 40px;
    text-align: left;
    padding-top: 6%;
    background: none;
    color: inherit;
    // border: none;
    padding: 5px;
    margin: 0;
    font: inherit;
    cursor: pointer;
  }

  // .navbarBM{
  //   display: none;
  // }
}

@media screen and (max-width: 1023px) {
  //iPad
  nav {
    font-family: "HelveticaLTBlkCond";
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100%;
    background-color: var(--primaryColor);
    flex-direction: column;
    top: 0;
    z-index: 999;
    .box_brand {
      padding-top: 25px;
      align-items: center;
      // padding-left: 50px;
      // justify-content: flex-start;
      .logo {
        height: 50px;
      }
    }
    .box_options {
      display: none;
    }
    .headerMobile {
      width: 100%;
      padding-top: 25px;
      .box_options_two {
        display: flex;
        align-items: center;
        flex-direction: row;
        background-color: rgb(239, 239, 239);
        justify-content: space-evenly;
        border-top: 1px solid var(--secondaryColor);
        height: 50px;
      }
      .btn {
        border-radius: 30px;
        min-width: 150px;
        height: 30px;
        padding-top: 5px;
        text-align: center;
        position: relative;
        background: var(--secondaryColor);
        color: var(--primaryColor) !important;
      }
      .btn:hover {
        // border-radius: 0px;
        background: var(--thirdColor);
        color: white !important;
      }
      .menu {
        position: absolute;
        top: 0;
        list-style-type: none;
        padding-top: 5px;
        height: 40px;
        padding: 0;
        min-width: 150px;
      }
      .menu-item {
        min-width: 150px;
        height: 40px;
        padding-top: 5px;
        text-align: center;
        position: relative;
        border-top: 1px solid var(--thirdColor);
      }
      .menu-item_special{
        min-width: 150px;
        height: 40px;
        padding-top: 5px;
        text-align: center;
        position: relative;
        border-top: 1px solid var(--secondaryColor);
      }
      .menu > li {
        margin: 0;
        color: var(--primaryColor);
        background-color: var(--secondaryColor);    
        text-align: left;
        padding-top: 2%;
        justify-content: center;
        padding: 3px;
      }
      .buttonMenu {
        padding-top: 3%;
        justify-content: center;
      }
      .menu > li:hover {
        background-color: var(--thirdColor);
      }
    
      .menu > li > button {
        width: 100%;
        height: 40px;
        text-align: left;
        padding-top: 6%;
        background: none;
        color: inherit;
        // border: none;
        padding: 5px;
        margin: 0;
        font: inherit;
        cursor: pointer;
      }
      ul {
        list-style: none;
        color: var(--secondaryColor);
      }
      li {
        margin: 10px;
      }
      a {
        margin: 0px;
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
}